<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Invite user</translate></h4>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="card-body">
            <div class="row">
              <FormInput
                class="col-md-6"
                field="first_name"
                :label="$gettext('First name')"
                v-model="payload.first_name"
                required
              />
              <FormInput
                class="col-md-6"
                v-model="payload.last_name"
                field="last_name"
                :label="$gettext('Last Name')"
                required
              />
              <FormInput
                class="col-md-6"
                v-model="payload.email"
                field="email"
                :label="$gettext('Email Address')"
                required
              />
              <FormInput
                class="col-md-6"
                v-model="payload.phone_number"
                field="phone_number"
                :label="$gettext('Phone Number')"
              />
            </div>

            <h4><translate>Permissions</translate></h4>
            <Checkbox
              field="permissions"
              :list="permissions"
              v-model="payload.permissions"
            />

            <router-link
              :to="{ name: 'users' }"
              href="interview.html"
              class="btn btn-danger"
              ><translate>Cancel</translate></router-link
            >
            <SubmitButton :label="$gettext('Invite')" class="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Checkbox from "@/components/Form/Checkbox";
import FormInput from "@/components/FormGroups/FormInput";
import SubmitButton from "@/components/SubmitButton";

export default {
  components: { SubmitButton, Checkbox, FormInput },
  data: () => ({
    payload: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      permissions: [
        "manage_users",
        "manage_companies",
        "manage_offers",
        "manage_candidates",
        "manage_subscriptions",
        "manage_credit_cards"
      ]
    }
  }),
  computed: {
    permissions() {
      return [
        { value: "manage_users", label: this.$gettext("Manage Users") },
        { value: "manage_companies", label: this.$gettext("Manage Companies") },
        { value: "manage_offers", label: this.$gettext("Manage Offers") },
        {
          value: "manage_candidates",
          label: this.$gettext("Manage Candidates")
        },
        {
          value: "manage_subscriptions",
          label: this.$gettext("Manage Subscriptions")
        },
        {
          value: "manage_credit_cards",
          label: this.$gettext("Manage Credit Cards")
        }
      ];
    }
  },
  methods: {
    ...mapActions("user", ["inviteUser"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      this.submit(() => this.inviteUser(this.payload)).then(() =>
        this.$router.push({ name: "users" })
      );
    }
  }
};
</script>
